import './AboutMobile.css';
import stock1 from '../images/stock1_three-dudes.jpg';
import { Helmet } from 'react-helmet-async';
function AboutMobile() {
    return (
        <div className="fadedInOutlet" id="aboutMobileScreen">
            <Helmet>
                <title>Leland MacDonald & Associates LLC - About on Mobile</title>
                <meta name="description"
                    content="About Leland MacDonald & Associates LLC, a professional land surveying firm in Yamhill County Oregon, serving McMinnville, Newberg, and outlying areas"/>
                <link rel="canonical" href="about"/>
            </Helmet>
            <div className="bannerMobile">
                <h3 className="mobileHeaderText">Leland MacDonald & Associates, LLC.</h3>
                <h5 className="mobileSubHeaderText">40+ Years of Professional Surveying</h5>
                <img id="aboutImageMobile" src={stock1} alt="Leland MacDonald & Associates LLC"/>
            </div>
            <div className="textGrayBG">
                <h3 className="mobileSubHeaderText">Our history:</h3>
                <p className="mobileParagraph">Established in 1981 as Matt Dunckel & Associates by Matt Dunckel, PLS, Leland MacDonald & Associates LLC is the top choice of both private and public clients for professional land surveying services in Yamhill County and throughout Western Oregon. </p>
            </div>
            <div className="textWhiteBG">
                <h3 className="mobileSubHeaderText">Our work:</h3>
                <p className="mobileParagraph">Our company is strongly committed to excellence in surveying, using survey grade GPS, Robotic & Conventional Total Station Instruments integrated with powerful surveying software in the office to deliver our services in a timely and efficient manner. Our experienced professionals treat all of our clients, their associates, and consultants with respect and integrity. We are committed to our responsibility toward the protection of the public and to that end uphold the Code of Ethics of the Professional Land Surveyors of Oregon, and the National Society of Professional Surveyors.</p>
            </div>
            <div className="textGrayBG">
                <h3 className="mobileSubHeaderText">Our surveyors:</h3>
                    <ul>
                        <li className="mobileParagraph">Lee MacDonald, PLS, CWRE, CfedS: lee@macdonaldsurveying.com</li>
                    </ul>
            </div>
            <div className="textWhiteBG">
                <h3 className="mobileSubHeaderText">Our technicians:</h3>
                <ul>
                    <li className="mobileParagraph">Arlo Kneeland-MacDonald, LSIT</li>
                    <li className="mobileParagraph">Shawn Dimick, Survey Tech II</li>
                    <li className="mobileParagraph">Zachary Best, Survey Tech II</li>
                    <li className="mobileParagraph">Aaron Albers, Survey Tech I</li>
                </ul>
            </div>
        </div>
    );
}

export default AboutMobile;