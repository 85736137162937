import { Outlet } from 'react-router-dom';
import NavigationBar from './NavigationBar.js';
import Banner from './Banner.js';
import BottomNav from './BottomNav.js';
import Credits from './Credits.js';
function Root() {
    return (
    <>
        <Banner/>
        <NavigationBar/>
        <Outlet/>
        <BottomNav/>
        <Credits/>
    </>
    
    
    );
}

export default Root;