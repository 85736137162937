import './SurveyingMobile.css';
import stock8 from '../images/stock8_two-dudes-crouch.jpg';
import { Helmet } from 'react-helmet-async';

function SurveyingMobile() {
    return (
        <div className="fadedInOutlet" id="surveyingMobileScreen">
            <Helmet>
                <title>Leland MacDonald & Associates LLC - Surveying</title>
                <meta name="description"
                    content="Learn about the surveying work that is possible with Leland MacDonald & Associates LLC"/>
                <link rel="canonical" href="surveying"/>
            </Helmet>
            <div className="bannerMobile">
                <h3 className="mobileHeaderText">Topographical Surveys in McMinnville, OR</h3>
                <h5 className="mobileSubHeaderText">Professional Land Surveying</h5>
                <img id="surveyingImageMobile" src={stock8} alt="Leland MacDonald & Associates LLC"/>
            </div>
            <h3 id="ourCompleteServices">Our Complete Surveying Services:</h3>
            <div className="textGrayBG">
                <ul className="mobileParagraph">
                    <li>Property Boundary Surveys</li>
                    <li>Timber Boundary Surveys</li>
                    <li>Lot/Property Line</li>
                    <li>Land Partition Surveys</li>
                </ul>
            </div>
            <div className="textWhiteBG">
                <ul className="mobileParagraph">
                    <li>Topographic Mapping Surveys</li>
                    <li>Subdivision Surveys</li>
                    <li>GPS Surveys</li>
                    <li>FEMA Elevation Certificates & Lomas</li>
                    <li>Construction Layout</li>
                </ul>
            </div>
            <div className="textGrayBG">
                <ul className="mobileParagraph">
                    <li>Engineering/Architectural Design Surveys</li>
                    <li>ALTA/ASCM Land Title Surveys</li>
                    <li>Property and Easement Legal Description</li>
                    <li>Construction Surveys</li>
                </ul>
            </div>
        </div>
    );
}

export default SurveyingMobile;