import './HomepageMobile.css';
import logo from '../images/logo.png';

function HomepageMobile() {
    return (
        <div className="fadedInOutlet" id="homepageMobileScreen">
			<div id="homepageBannerMobile">
				<h3 id="homepageHeaderText">Leland MacDonald & Associates, LLC.</h3>
				<h5 id="homepageSubHeaderText">Professional Land Surveyors in McMinnville, OR</h5>
				<img src={logo} id="homepageLogoMobile" alt="Leland McDonald & Associates LLC"/>
			</div>
			<div className="textGrayBG">
				<h3 className="blueHeader">Business Information</h3>
				<p className="mobileParagraph">Registered Oregon Public Land Surveyor</p>
				<p className="mobileParagraph">Certified Water Rights Examiners</p>
				<p className="mobileParagraph">Certified for Indian Lands Boundaries</p>
				<p className="mobileParagraph">Appointments: (503) 472-7904</p>
				<p className="mobileParagraph">Open on Weekdays from 8 AM to 5 PM</p>
			</div>
			<div className="textWhiteBG">
				<h3 className="blueHeader">Surveying</h3>
				<p className="mobileParagraph">Make sure your next surveying project benefits from our boundary and construction surveying skill sets.</p>
			</div>
			<div className="textGrayBG">
				<h3 className="blueHeader">Contact</h3>
				<p className="mobileParagraph">Taking care of certain surveying requirements now will save you time and effort later. Before you start any work on or near your property boundaries, contact our surveyors.</p>
			</div>
		</div>
    );
}

export default HomepageMobile;