import './Surveying.css';
import stock_two from './images/stock2_two-dudes-road.jpg';
import { Helmet } from 'react-helmet-async';

function Surveying() {
  return (
  <div className="fadedInOutlet" id="surveyingScreen">
    <Helmet>
        <title>Leland MacDonald & Associates LLC - Surveying</title>
        <meta name="description"
              content="Learn about the surveying work that is possible with Leland MacDonald & Associates LLC"/>
        <link rel="canonical" href="surveying"/>
    </Helmet>
    <div id="buildingInWrongPlace">
      <img id="surveyingStockIMG"src={stock_two} alt="Leland MacDonald & Associates LLC"/>
      <div id="buildingInWrongPlaceText">
        <p>Leland MacDonald & Associates LLC helps you avoid encroachment and construction issues by performing the most accurate topographical and construction staking surveys possible. With our assistance, you have a clear idea about all features of a property before site preparation begins. Depend on us for comprehensive surveying that includes:</p>
        <ul>
          <li>Boundary Surveys</li>
          <li>Alta/ACSM Surveys</li>
          <li>Topographic Surveys</li>
          <li>Design Surveys</li>
          <li>Partitions</li>
          <li>Subdivison & Lot Line Adjustment Surveys</li>
          <li>FEMA Flood Certifications & LOMA's</li>
          <li>Water Rights</li>
          <li>Construction Staking</li>
        </ul>
      </div> 
    </div>
    <div id="professionalLandSurveying">
      <h3 id="professionalLandSurveyingHeader">Professional Land Surveying</h3>
      <p id="professionalLandSurveyingParagraph">We offer a full selection of surveying services! Our trained professionals perform high-quality, top-rated work. To learn more about how we can help your project, visit our McMinnville office at 3885 NE Riverside Dr., or call 503-472-7904, for a free consultation.</p>
    </div>
    <div id="completeSurveying">
      <h3 id="completeSurveyingHeader">Our Complete Surveying Services:</h3>
      <div id="completeSurveyingList">
        <ul>
          <li>Property Boundary Surveys</li>
          <li>Timber Boundary Surveys</li>
          <li>Lot/Property Line Adjustment Surveys</li>
          <li>Land Partition Surveys</li>
          <li>Topographic Mapping Surveys</li>
        </ul>
        <ul>
          <li>Subdivision Surveys</li>
          <li>GPS Surveys</li>
          <li>FEMA Elevation Certificates & Lomas</li>
          <li>Construction Layout</li>
        </ul>
        <ul>
          <li>Engineering/Architectural Design Surveys</li>
          <li>ALTA/ASCM Land Title Surveys Property and Easement Legal Description</li>
          <li>Property and Easement Legal Description</li>
          <li>Construction Surveys</li>
        </ul>
      </div>
    </div>
    <div id="surveyingBottomBox">
      <p id="surveyingBottomText">Contact us for topographical and aerial photo control surveys that streamline the construction process!</p>
    </div>
  </div>
  );
}

export default Surveying;