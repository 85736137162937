import { Link } from 'react-router-dom';
import './NavigationBar.css';

function NavigationBar() {
    return (
    <nav>
        <div id="navDiv">
            <Link className="navText" id="firstNav" to="/"><div className="navSection">Home</div></Link>
            <Link className="navText" to="/about"><div className="navSection">About Us</div></Link>
            <Link className="navText" to="/surveying"><div className="navSection">Surveying</div></Link>
            <Link className="navText" id="lastNav" to="/contact"><div className="navSection">Contact Us</div></Link>
        </div>
    </nav>
    );
}

export default NavigationBar;