import './NavMobile.css';
import { Outlet, Link } from 'react-router-dom';

function NavMobile() {
    return (
    <div id="mobileRootScreen">
        <nav id="mobileNavDiv">
            <div className="mobileNavText">
                <Link className="mobileLink" to="/">Home</Link>
            </div>
            <div className="mobileNavText">
                <Link className="mobileLink" to="/about">About</Link>
            </div>
            <div className="mobileNavText">
                <Link className="mobileLink" to="/surveying">Surveying</Link>
            </div>
            <div className="mobileNavText">
                <Link className="mobileLink" to="/contact">Contact</Link>
            </div>
        </nav>
        <div id="restOfPage">
            <Outlet/>
            <div id="mobileCreditsParent">
                <p id="mobileCreditsMsg">This site was produced by Matt Bagley, a professional web-developer based in Dayton, OR.</p>
                <a href="http://bagleycode.com" target="_blank" rel="noopener noreferrer">BagleyCode.com</a>
            </div>
        </div>
    </div>
    );
}

export default NavMobile;