import { Link } from 'react-router-dom';
import './BottomNav.css';

function BottomNav() {
    return (
        <nav id="bottomNavSection">
            <Link className="bottomNav" id="firstNavMargin" to="/">Home</Link>
            <Link className="bottomNav" id="secondNavMargin" to="/about">About Us</Link>
            <Link className="bottomNav" id="secondNavMargin" to="/surveying">Surveying</Link>
            <Link className="bottomNav" id="secondNavMargin" to="/contact">Contact Us</Link>        
        </nav>
    );
}

export default BottomNav;