import './ContactMobile.css';

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import emailjs from '@emailjs/browser';
import { Helmet } from 'react-helmet-async';

function ContactMobile() {
    const navigate = useNavigate();
    const [name, setName] = useState(null);
    const [phone, setPhone] = useState(null);
    const [comments, setComments] = useState(null);
    const [email, setEmail] = useState(null);
    const [selection, setSelection] = useState(null);
    
    const handleSubmit = (e) => {
        e.preventDefault();
        let selectionEmail = null;
        let selectionName = null;

        switch(selection) {
            case "arlo":
                selectionEmail = "Arlo@MacDonaldSurveying.com";
                selectionName = "Arlo Kneeland-MacDonald";
            break;

            case "lee":
                selectionEmail = "Lee@MacDonaldSurveying.com";
                selectionName = "Lee MacDonald";
            break;

            case "shawn":
                selectionEmail = "Shawn@MacDonaldSurveying.com";
                selectionName = "Shawn Dimick";
            break;

            case "jodi":
                selectionEmail = "Jodi@MacDonaldSurveying.com";
                selectionName = "Jodi Kneeland";
            break;

            default:
                selectionEmail = "lee@MacDonaldSurveying.com";
                selectionName = "Default - General Inquiries";
            break;

            
          }
          
          emailjs.send("service_rgqwhil","template_jq86pd3", {
            name: name,
                  phone: phone,
                  email: email,
                  comments: comments,
                  selectionEmail: selectionEmail,
                  selectionName: selectionName
          }, "WGgd1EorjuG0wFW5d");

          alert("Thank you! A staff member will respond to you soon!");
          setTimeout(navigate("../"), 3000);
    }

    return (
        <div className="fadedInOutlet" id="contactMobileScreen">
            <Helmet>
                <title>Leland MacDonald & Associates LLC - Contact on Mobile</title>
                <meta name="description"
                    content="Need help? Our professional land surveyors are here for you! Type in your contact information and we will get back to you as soon as possible!"/>
                <link rel="canonical" href="contact"/>
            </Helmet>
            <h3 className="mobileHeaderText">Land Surveying Questions in McMinnville, OR</h3>
            <p className="mobileSubHeaderText">Thank you for your interest. You can reach us in one of several ways:</p>
            <ul className="mobileParagraph">
                <li>Phone: (503) 472-7904</li>
                <li>Email: lee@macdonaldsurveying.com</li>
                <li>If you'd like, you can fill out the contact form below</li>
            </ul>
            <form id="contactFormMobile" onSubmit={handleSubmit}>
                <input type="text" id="nameMobile" className="contactInputMobile" value={name} onChange={(e)=>{setName(e.target.value)}} placeholder="Your name" required></input><br/>
                <input type="text" id="phoneMobile" className="contactInputMobile" value={phone} onChange={(e)=>{setPhone(e.target.value)}} placeholder="503-000-0000" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" required></input><br/>
                <input type="text" id="emailMobile" className="contactInputMobile" value={email} onChange={(e)=>{setEmail(e.target.value)}} placeholder="yourEmail@yourEmailAddress.com" required></input><br/>
                <textarea rows="6" cols="40" id="contactCommentMobile" wrap="hard" value={comments} onChange={(e)=>{setComments(e.target.value)}} placeholder="Enter your comments/questions here..."></textarea><br/><br/>
                <p id="surveyorSelectText">Is there a particular employee you would like to contact?</p>
                <select form="contactSelect" name="surveyors" id="surveyorSelect" onChange={(e)=>{setSelection(e.target.value)}}>
                    <option class="contactOption" value="default">n/a - general inquiries</option>
                    <option class="contactOption" value="arlo">Arlo Kneeland-MacDonald</option>
                    <option class="contactOption" value="lee">Lee MacDonald</option>
                    <option class="contactOption" value="shawn">Shawn Dimick</option>
                    <option class="contactOption" value="jodi">Jodi Kneeland</option>
                </select><br/><br/>
                <input type="submit" id="submitMobile"></input><br/>
            </form>
        </div>
    );
}

export default ContactMobile;