import './About.css';
import { Helmet } from 'react-helmet-async';

function About() {
  return (
  <div className="fadedInOutlet" id="aboutScreen">
    <Helmet>
        <title>Leland MacDonald & Associates LLC - About</title>
        <meta name="description"
              content="About Leland MacDonald & Associates LLC, a professional land surveying firm in Yamhill County Oregon, serving McMinnville, Newberg, and outlying areas"/>
        <link rel="canonical" href="about"/>
      </Helmet>
    <div id="ourHistorySection">
      <h1 id="ourHistoryHeader">Our history</h1>
        <p id="ourHistoryParagraph">Established in 1981, <span className="boldText"> Leland MacDonald & Associates LLC </span> is the top choice of both private and public clients for professional land surveying services in Yamhill County and throughout Western Oregon. Our company is strongly committed to excellence in surveying, using survey grade GPS, Robotic & Conventional Total Station Instruments integrated with powerful surveying software in the office to deliver our services in a timely and efficient manner. </p>
    </div>
    <div id="ourSurveyor">
      <h3 id="surveyorHeader">Our surveyors: </h3>
      <ul>
        <li id="surveyorText">Lee MacDonald, PLS, CWRE, CfedS: lee@macdonaldsurveying.com</li>
      </ul>
    </div>
    <div id="ourTechnicians">
      <h3 id="techniciansHeader">Our technicians: </h3>
      <ul id="techniciansText">
          <li>Arlo Kneeland-MacDonald, LSIT</li>
          <li>Shawn Dimick, Survey Tech II</li>
          <li>Zachary Best, Survey Tech II</li>
          <li>Aaron Albers, Survey Tech I</li>
      </ul>
    </div>
    <div id="learnMoreAbout">
      <p id="learnMoreParagraph">Please take the opportunity to browse through our website for more information about our company and what we offer. If you require land surveying services for your next project, feel free to get in touch with us. Call us at (503) 472-7904 to schedule an appointment. We look forward to working with you.</p>
    </div>
  </div>);
}

export default About;