import './Credits.css';

function Credits() {
    return (
    <div id="creditsParent">
        <p id="creditsMsg">This site was produced by Matt Bagley, a professional web-developer based in Dayton, OR.</p>
        <div id="creditsChild">
            <a href="http://bagleycode.com" target="_blank" rel="noopener noreferrer" className="creditsLinks">BagleyCode.com</a>
        </div>
    </div>
    
    );
}

export default Credits;