import logo from './images/logo.png';
import google from './images/google.png';

import './Banner.css';

function Banner() {
    return (
    <div id="banner">
       <img id="logoIMG" src={logo} alt="logo for Leland MacDonald & Associates LLC"/> 
       <div id="bannerText">
           <h1 id="bannerHeading">Leland MacDonald & Associates, LLC.</h1>
           <div id="bannerTextStyle">
                <p className="bannerTextPara" id="callUsText"><span id="boldCall">Call us today!</span> (503) 472-7904</p>
                <p className="bannerTextPara">3885 NE Riverside Dr, McMinnville OR 97128</p>
            </div>
       </div>
       <div id="bannerChildRightCorner">
            <div id="facebookGoogleIcons">
                <a href="https://www.google.com/maps/place/MacDonald+and+Associates+Land+Surveying/@45.230002,-123.161099,17z/data=!3m1!4b1!4m5!3m4!1s0x54954626c537a6b5:0xbcbd44636e4b9282!8m2!3d45.230002!4d-123.1589103" target="_blank" rel="noopener noreferrer">
                    <img id="googleIMG" src={google} alt="Google Business icon link"/>
                </a>
                
            </div>
       </div>
    </div>
    
    );
}

export default Banner;