/* 
* collect name, phone, email address, comments/questions, and contactOption (the email they want it sent to) text in stateful data
* write useStates for all of those params, assign the default state of each hook (null) to the value attribute in the <input> tags, 
* keep the placeholders
* write onChange handlers in the <input> tags for all of those params that set the state hook's value to e.target.value
* write an onChange handler in the <select> tag that assigns event.target.value to the contactOption hook 
* write an OnSubmit handler in the form that sends the value of those hooks in a POST request to the server and then re-directs the user home
*/

import './Contact.css';

import { useState } from 'react';

import emailjs from '@emailjs/browser';

import { useNavigate } from 'react-router-dom';

import { Helmet } from 'react-helmet-async';

function Contact() {
  const navigate = useNavigate();
  const [name, setName] = useState(null);
  const [phone, setPhone] = useState(null);
  const [comments, setComments] = useState(null);
  const [email, setEmail] = useState(null);
  const [selection, setSelection] = useState(null);
  
  const handleSubmit = (e) => {
    e.preventDefault();
    let selectionEmail = null;
    let selectionName = null;

    switch(selection) {
        case "arlo":
            selectionEmail = "Arlo@MacDonaldSurveying.com";
            selectionName = "Arlo Kneeland-MacDonald";
        break;

        case "lee":
            selectionEmail = "Lee@MacDonaldSurveying.com";
            selectionName = "Lee MacDonald";
        break;

        case "shawn":
            selectionEmail = "Shawn@MacDonaldSurveying.com";
            selectionName = "Shawn Dimick";
        break;

        case "jodi":
            selectionEmail = "Jodi@MacDonaldSurveying.com";
            selectionName = "Jodi Kneeland";
        break;

        default:
            selectionEmail = "lee@MacDonaldSurveying.com";
            selectionName = "Default - General Inquiries";
        break;
      }
      
      emailjs.send("service_rgqwhil","template_jq86pd3", {
          name: name,
          phone: phone,
          email: email,
          comments: comments,
          selectionEmail: selectionEmail,
          selectionName: selectionName
      }, "WGgd1EorjuG0wFW5d");

      alert("Thank you! A staff member will respond to you soon!");
      setTimeout(navigate("../"), 3000);
}
  
  return (
    <div className="fadedInOutlet" id="contactScreen">
      <Helmet>
        <title>Leland MacDonald & Associates LLC - Contact</title>
        <meta name="description"
              content="Need help? Our professional land surveyors are here for you! Type in your contact information and we will get back to you as soon as possible!"/>
        <link rel="canonical" href="contact"/>
      </Helmet>
      <h3 id="contactHeader">Contact Us</h3>
      <div id="textAndForm">
        <div id="contactText">
          <p>Thank you for your interest. Please fill out the contact form on the right, or call/email us directly, using the contact information provided below: </p>
          <p>Phone: (503) 472-7904</p>
          <p>Email: lee@macdonaldsurveying.com</p>
          <p>Proudly Serving Oregon with a Primary Focus on the Willamette Valley & Surrounding Areas, including Yamhill, Polk, Marion, Lincoln, and Tillamook Counties</p>
          <p>Visit our McMinnville office at 3885 NE Riverside Dr., or call 503-472-7904, for personalized service that makes sure your surveying requests are fulfilled.</p>
        </div>
        <form id="contactForm" onSubmit={handleSubmit}>
          <input type="text" id="name" class="contactInput" value={name} onChange={(e)=>{setName(e.target.value)}} placeholder="Your name" required></input><br/><br/>
          <input type="text" id="phone" class="contactInput" value={phone} onChange={(e)=>{setPhone(e.target.value)}} placeholder="503-000-0000" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" required></input><br/><br/>
          <input type="text" id="email" class="contactInput" value={email} onChange={(e)=>{setEmail(e.target.value)}} placeholder="yourEmail@yourEmailAddress.com" required></input><br/><br/>
          <textarea rows="6" cols="40" id="contactComment" value={comments} onChange={(e)=>{setComments(e.target.value)}} wrap="hard" placeholder="Enter your comments/questions here..."></textarea><br/>
          <p id="surveyorSelectText">Is there a particular employee you would like to contact?</p>
          <select form="contactSelect" name="surveyors" id="surveyorSelect" onChange={(e)=>{setSelection(e.target.value)}}>
            <option class="contactOption" value="default">n/a - general inquiries</option>
            <option class="contactOption" value="arlo">Arlo Kneeland-MacDonald</option>
            <option class="contactOption" value="lee">Lee MacDonald</option>
            <option class="contactOption" value="shawn">Shawn Dimick</option>
            <option class="contactOption" value="jodi">Jodi Kneeland</option>
          </select><br/><br/>
          <input type="submit" id="submit"></input><br/>
        </form>
      </div>
    </div>
  );
}

export default Contact;