// CSS styling for the App router level
import './App.css';

// JavaScript files for the Desktop routes
import Homepage from './Homepage.js';
import Root from '../root/Root.js';
import About from '../about/About.js';
import Contact from '../contact/Contact.js';
import Surveying from '../surveying/Surveying.js';

// JavaScript files for the Mobile routes
import HomepageMobile from '../mobile/homepage/HomepageMobile.js';
import NavMobile from '../mobile/nav/NavMobile.js';
import AboutMobile from '../mobile/about/AboutMobile.js';
import ContactMobile from '../mobile/contact/ContactMobile.js';
import SurveyingMobile from '../mobile/surveying/SurveyingMobile.js';

import { createBrowserRouter, createRoutesFromElements, RouterProvider, Route } from 'react-router-dom';

import { Helmet } from 'react-helmet-async';

function App() {
  const routerDesktop = createBrowserRouter(createRoutesFromElements(
    <Route path="/" element={ <Root/> }>
      <Route index element={ <Homepage/>}/>
      <Route path="about" element={ <About/>}/>
      <Route path="surveying" element={ <Surveying/>}/>
      <Route path="contact" element={ <Contact/>}/>
    </Route>
  ));

  const routerMobile = createBrowserRouter(createRoutesFromElements(
    <Route path="/" element={ <NavMobile/> }>
      <Route index element={ <HomepageMobile/> }/>
      <Route path="about" element={ <AboutMobile/> }/>
      <Route path="surveying" element={ <SurveyingMobile/> }/>
      <Route path="contact" element={ <ContactMobile/> }/>
    </Route>
  ));

  return (
    <>
      <Helmet>
        <title>Leland MacDonald & Associates LLC - Homepage</title>
        <meta name="description"
              content="Leland MacDonald & Associates LLC is a professional land surveying firm in Yamhill County Oregon, serving McMinnville, Newberg, and outlying areas"/>
        <link rel="canonical" href="/"/>
      </Helmet>
      <div className="fadedInOutlet" id="desktopApp">
        <RouterProvider router={routerDesktop}/>   
      </div>
      <div className="fadedInOutlet" id="mobileApp">
        <RouterProvider router={routerMobile}/>
      </div>
    </>
  );
}

export default App;
