import stock_three from './images/stock3_dude-dudette-field.jpg';
import stock_four from './images/stock4_tool.jpg';
import stock_seven from './images/stock7_dudette-lever.jpg';
import './Homepage.css';
function Homepage() {
    return (
    <div className="fadedInOutlet" id="homepageScreen">
        <div id="homepageBanner">
            <img src={stock_three} alt="Leland McDonald & Associates Surveying" className="homepageBannerIMG" />
            <img src={stock_four} alt="Leland McDonald & Associates Surveying" className="homepageBannerIMG" />
            <img src={stock_seven} alt="Leland McDonald & Associates Surveying" className="homepageBannerIMG" />
        </div>
        <h1 id="introTextHeader">Professional Land Surveyors in McMinnville, OR</h1>
        <div className="introTextBorderBlue">
            <h3 className="introTextHeaderWhite">We are:</h3>
            <ul>
                <li className="listTextParagraphWhite">Registered Oregon Public Land Surveyor</li>
                <li className="listTextParagraphWhite">Open on Weekdays from 8 AM to 5 PM</li>
                <li className="listTextParagraphWhite">Certified Water Rights Examiners</li>
                <li className="listTextParagraphWhite">Certified for Indian Lands Boundaries</li>
            </ul>
        </div>
        <div id="introTextDivider">
            <div className="introTextColumn">
                <h3 className="introTextHeaderArgent">Surveying</h3>
                <p className="introTextParagraphBlue">We offer assistance with boundary surveys, lot line adjustments, partitions & subdivision, staking & marking, and more! Make sure your next project benefits from our professional skill sets.</p>
            </div>
            <div className="introTextColumn">
                <h3 className="introTextHeaderArgent">Contact</h3>
                <p className="introTextParagraphBlue">Our McMinnville office is open every Monday through Friday from 7 AM to 5 PM. A quick, free phone consultation with our professional surveyors can help you get started on your next boundary, design, or construction project!</p>
            </div>
        </div>
        <div id="learnMoreSection">
            <p class="learnMoreText">Save money on building costs with construction surveying from our land surveyors in McMinnville, Oregon. For more than 40 years, Leland MacDonald & Associates has provided clients with comprehensive services. Using technological advantages and an efficient workflow, we optimize your surveys. Leland MacDonald & Associates LLC are members of the Professional Land Surveyors of Oregon, so we have the professional standing to give you peace of mind about our work.</p>
        </div>
    </div>
    );
}

export default Homepage;